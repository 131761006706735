<template>
  <div>
      <div class="banner">
        <div class="bannerSearch">
          <p>学术论文版权登记证书查询</p>
          <div>
            <input type="text" placeholder="请输入作品名称或证书编号" v-model="searchText" @keyup.enter="searchCertificate">
            <button @click="searchCertificate"><span class="icon iconfont">&#xe653;</span></button>
          </div>
        </div>
      </div>


      <div class="Router">
        <div class="container">
          <router-link to="/Home">首页</router-link> > 登记列表
        </div>
      </div>

      <div class="list">
          <div class="container">
              <a-row>
                <a-col :md="24" :lg="4" :xl="4">
                    <ul class="listTitle">
                        <li>论文分类</li>
                        <li v-for="(item, index) in listTitle" :key="index" :class="listTitleIndex == index?'active':''" @click="changeListTitle(index)">{{item.title}}</li>
                    </ul>
                </a-col>
                <a-col :md="0" :lg="1" :xl="1"></a-col>
                <a-col :md="24" :lg="19" :xl="19">
                    <div class="listContent">
                        <div class="contentTop">
                            <div v-if="!searchStatus">
                                <p>共计 <span>{{pagination.total}}</span> 条结果</p>
                                <!-- <p>浏览量</p>
                                <p>时间</p> -->
                                <!-- <el-table>
                                  <el-table-column
                                    prop="num"
                                    label="数量"
                                    sortable
                                    width="80"
                                  >
                                  </el-table-column>
                                </el-table> -->
                                
                            </div>
                            <div v-else>
                              <p>检索词：<span>{{searchReslut}}</span> 全部查询结果{{pagination.total}}条</p>
                            </div>
                            <!-- <div>
                                <input type="text" placeholder="请输入搜索关键字">
                                <img src="../../assets/images/search2.png" alt="">
                            </div> -->
                        </div>
                        <div class="content" v-if="list.length>0">
                            <div class="contentLi" v-for="(item, index) in list" :key="index">
                                <el-image v-if="item.certificateInfo" :src="item.certificateInfo.jpgUrl" :preview-src-list="[item.certificateInfo.jpgUrl]"></el-image>
                                <img v-else class="el-image" src="../../assets/images/certificateNone.png" />
                                <div>
                                    <p v-if="item.certificateInfo && Object.keys(item.certificateInfo).length != 0">{{item.certificateInfo.body.data.registerCode}}</p><p v-else>--</p>
                                    <!-- <router-link :to="{path:'/Register/detail',query: {orderNo:item.orderNo}}">{{item.subject}}</router-link> -->
                                    <router-link to="">{{item.subject}}</router-link>
                                    <!-- <p><span>著作权人：</span>{{item.certificateInfo.body.data.obligee}}</p> -->
                                    <p v-if="item.certificateInfo && Object.keys(item.certificateInfo).length != 0"><span>作者：</span>{{item.certificateInfo.body.data.author}}</p><p v-else><span>作者：</span>--</p>
                                    <p><span>登记时间：</span>{{item.createTimeText}}</p>
                                </div>
                                <button v-if="item.certificateInfo" @click="previewImg(item.certificateInfo.jpgUrl)">查看证书</button>
                            </div>
                            <el-pagination
                              background
                              layout="prev, pager, next"
                              :total="pagination.total"
                              :current-page.sync="pagination.page"
                              :page-size.sync="pagination.pageSize"
                              @size-change="sizeChange"
                              @current-change="currentChange"
                              :pagerCount="5" 
                            >
                            </el-pagination>
                        </div>
                        <div class="content content_none" v-if="list.length==0">
                          <img src="../../assets/images/none.png" alt="">
                          <p>抱歉，没有找到与 “{{searchReslut}}” 相关的结果</p>
                        </div>
                    </div>
                </a-col>
            </a-row>
          </div>
      </div>

      <!-- 图片预览 -->
      <el-image-viewer
        v-if="showImageViewer"
        :url-list="[showImageUrl]"
        hide-on-click-modal
        teleported
        :on-close="closePre"
      />
  </div>
</template>

<script>
import { queryRegisterList } from '@/api/EvidenceService'
export default {
  components: {
      'el-image-viewer': () => import('element-ui/packages/image/src/image-viewer')
  },
  data () {
    return {
        searchText: '',
        searchReslut: '',
        searchStatus: false,
        listTitle: [
            {title: '期刊',num: '21200'},
            {title: '学位',num: '2390'},
            {title: '会议',num: '738'},
            {title: '专利',num: '2190'},
            {title: '科技报告',num: '182'},
        ],
        listTitleIndex: 0,
        list: [
            // {jpgUrl: 'https://t7.baidu.com/it/u=234231168,3860178184&fm=167&app=3000&size=w560&n=0&f=JPEG&fmt=auto?sec=1688576400&t=88fa874295293bede935f6807aa30240',title: '总体国家安全观融入军事理论课教学的价值、困境及路径',no: '鄂作登字-2023-A-32784732894732',author: '段晓芸 高士云 张林丽',time: '2023-02-19'},
            // {jpgUrl: '../../assets/images/certificate2.png',title: '总体国家安全观融入军事理论课教学的价值、困境及路径',no: '鄂作登字-2023-A-32784732894732',author: '段晓芸 高士云 张林丽',time: '2023-02-19'},
            // {jpgUrl: '../../assets/images/certificate2.png',title: '总体国家安全观融入军事理论课教学的价值、困境及路径',no: '鄂作登字-2023-A-32784732894732',author: '段晓芸 高士云 张林丽',time: '2023-02-19'},
            // {jpgUrl: '../../assets/images/certificate2.png',title: '总体国家安全观融入军事理论课教学的价值、困境及路径',no: '鄂作登字-2023-A-32784732894732',author: '段晓芸 高士云 张林丽',time: '2023-02-19'},
            // {jpgUrl: '../../assets/images/certificate2.png',title: '总体国家安全观融入军事理论课教学的价值、困境及路径',no: '鄂作登字-2023-A-32784732894732',author: '段晓芸 高士云 张林丽',time: '2023-02-19'},
            // {jpgUrl: '../../assets/images/certificate2.png',title: '总体国家安全观融入军事理论课教学的价值、困境及路径',no: '鄂作登字-2023-A-32784732894732',author: '段晓芸 高士云 张林丽',time: '2023-02-19'},
            // {jpgUrl: '../../assets/images/certificate2.png',title: '总体国家安全观融入军事理论课教学的价值、困境及路径',no: '鄂作登字-2023-A-32784732894732',author: '段晓芸 高士云 张林丽',time: '2023-02-19'},
            // {jpgUrl: '../../assets/images/certificate2.png',title: '总体国家安全观融入军事理论课教学的价值、困境及路径',no: '鄂作登字-2023-A-32784732894732',author: '段晓芸 高士云 张林丽',time: '2023-02-19'},
        ],
        pagination: {
          total:0,
          page: 1,
          pageSize: 10
        },
        showImageViewer: false,
        showImageUrl: '',
    }
  },
  created() {
    this.searchCertificate();
  },
  methods: {
    changeListTitle(index){
        this.listTitleIndex = index;
        this.searchText = '';
        this.searchStatus = false;
    },
    // 查看证书放大图片
    previewImg(jpgUrl){
      document.body.style.overflow = 'hidden';
      this.showImageUrl = jpgUrl;
      this.showImageViewer = true;
    },
    closePre(){
      document.body.style.overflow = 'auto';
      this.showImageUrl = '';
      this.showImageViewer = false;
    },

    // 搜索证书
    searchCertificate(){
      // if(this.searchText==''){
      //   this.$message({
      //       type: 'warning',
      //       message: '请输入作品名称或证书编号'
      //   });
      //   return false
      // }
      if(this.searchText!=''){
        this.searchStatus = true;
        this.searchReslut = this.searchText;
      }else{
        this.searchStatus = false;
      }
      let params = {
        status: "21",
        keywords: this.searchText,
      }
      queryRegisterList(this.pagination.page,this.pagination.pageSize,params).then(({data}) => {
				console.log("搜索登记列表",data.list);
        for (let i = 0; i < data.list.length; i++) {
          data.list[i].createTimeText= this.dayjs(data.list[i].createTime*1000).format('YYYY-MM-DD HH:mm:ss ');
        }
        this.list = data.list;
        this.pagination.total=data.total*1;
			})
    },

    sizeChange (pageSize) {
      this.pageSize = pageSize
      this.pageNum = 1
      this.searchCertificate()
    },
    currentChange (pageNum) {
      // 更新表格数据
      this.searchCertificate()
    },

  },
}
</script>

<style lang="scss" scoped>
  .banner{
    height: 240px;
    background: url(../../assets/images/registerListBg.png)no-repeat;
    background-size: cover;
    padding: 48px 0;
    .bannerSearch{
      width: 830px;
      margin: 0 auto;
      p{
        font-size: 28px;
        font-family: 'SourceHanSansCN, SourceHanSansCN';
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
      }
      div{
        display: flex;
        align-items: center;
        justify-content: center;
        input{
          width: 600px;
          height: 46px;
          border-top-left-radius: 23px;
          border-bottom-left-radius: 23px;
          font-size: 14px;
          border: none;
          outline: none;
          padding: 0 20px;
          margin: 40px 0;
        }
        button{
          width: 60px;
          height: 46px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          border-top-right-radius: 23px;
          border-bottom-right-radius: 23px;
          background: #FFFFFF;
          cursor: pointer;
          text-align: center;
          span{
            font-size: 22px;
            color: #898989;
          }
        }
      }
    }
  }
  .Router{
    line-height: 40px;
    padding-top: 10px;
    font-size: 14px;
    color: #000000;
    a{
      text-decoration: none;
      color: #0077FF;
    }
  }
  .list{
      margin-bottom: 50px;
      .listTitle{
        background: #FFFFFF;
        box-shadow: 0px 0px 11px 0px rgba(83,138,238,0.1);
        // border-radius: 4px;
        overflow: hidden;
        li{
            padding: 0 20px;
            height: 48px;
            line-height: 48px;
            font-size: 16px;
            color: #65686C;
            border-bottom: 1px solid #E8EDF1;
            cursor: pointer;
            padding-left: 50px;
        }
        li:nth-child(1){
            cursor: auto;
            background: linear-gradient(270deg, #0092FA 0%, #104189 100%);
            color: #FFFFFF;
        }
        li.active{
            color: #1353B4;
            background: #F7FCFF;
            position: relative;
        }
        li.active::before{
          content: '';
          width: 3px;
          height: 48px;
          background: #008EF5;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      .listContent{
          .contentTop{
            height: 58px;
            line-height: 48px;
            // background: #ECECEC;
            background: #FFFFFF;
            // border: 1px solid #BDBDBD;
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            div{
                display: flex;
                align-items: center;
                p{
                    font-size: 14px;
                    color: #22375E;
                    margin-right: 20px;
                    cursor: pointer;
                    span{
                        color: #D82020;
                    }
                }
                p:nth-child(1){
                    cursor: auto;
                }
            }
            div:nth-child(2){
                width: 160px;
                height: 32px;
                background: #FFFFFF;
                border-radius: 2px;
                padding: 0 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                input{
                    font-size: 12px;
                    height: 32px;
                    border: none;
                    outline: none;
                }
                img{
                    width: 16px;
                    height: 16px;
                    cursor: pointer;
                }
            }
          }
          .content{
              background: #FFFFFF;
              padding: 0 20px;
              min-height: 600px;
              .el-pagination{
                margin: 20px 0;
              }
              .el-pagination .el-pager li.active{
                border-color: #1353B4;
                color: #1353B4;
              }
              .contentLi{
                  padding: 20px;
                  // border-bottom: 1px solid #DEDEDE;
                  background: linear-gradient(270deg, #FEFEFE 0%, #F4F6F8 100%);
                  box-shadow: 0px 0px 45px 0px rgba(183,203,226,0.16);
                  border: 3px solid #FFFFFF;
                  margin-bottom: 20px;
                  display: flex;
                  align-items: center;
                  .el-image{
                      width: 170px;
                      height: 130px;
                      margin-right: 20px;
                      cursor: pointer;
                  }
                  button{
                      width: 120px;
                      height: 40px;
                      border-radius: 20px;
                      background: linear-gradient(270deg, #0092FA 0%, #104189 100%);
                      font-size: 16px;
                      font-size: 16px;
                      font-family: 'SourceHanSansCN, SourceHanSansCN';
                      font-weight: 400;
                      color: #FFFFFF;
                      cursor: pointer;
                      border: none;
                  }
                  button:hover{
                      background: linear-gradient(270deg, #104189 0%, #0092FA 100%);
                      color: #FFFFFF;
                  }
                  div{
                      width: calc(100% - 270px);
                      a{
                          display: block;
                          text-decoration: none;
                          font-size: 18px;
                          color: #000000;
                          margin-bottom: 10px;
                          margin-top: 5px;
                      }
                      a:hover{
                          color: #0873C0;
                      }
                      p{
                          font-size: 14px;
                          color: #737889;
                          line-height: 24px;
                          span{
                            color: #000000;
                            opacity: 0.35;
                          }
                      }
                      p:nth-child(1){
                          display: inline-block;
                          background: #EAF3FF;
                          padding: 0 5px;
                          height: 24px;
                          line-height: 24px;
                          color: #104189;
                          margin-top: 7px;
                          margin-bottom: 5px;
                      }
                  }
              }
          }
          .content_none{
            text-align: center;
            padding: 50px 0;
            img{
              width: 168px;
              height: 168px;
            }
            p{
              color: #65686C;
              line-height: 22px;
              font-size: 14px;
            }
          }
      }
  }


@media screen and (max-width: 991px){
  .banner .bannerSearch{max-width: 90%;}
  .banner .bannerSearch div input{width: calc(100% - 60px);}
  .contentLi{display: block!important;}
  .contentLi div{width: 100%!important;}
  .contentLi .el-image{width: 170px!important;margin: 0 auto!important;display: block;}
  .contentLi button{margin-top: 10px;}
}

</style>
